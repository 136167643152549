export const THEMES = {
  // LIGHT: 'LIGHT',
  // DARK: 'DARK',
  // NATURE: 'NATURE',
  SPECBYTE: 'SPECBYTE',
  CASHBYCREATIVE: 'CASHBYCREATIVE',
  CCC: 'CCC',
  CREATIVELOVE: 'CREATIVELOVE',
  AUDACYFLASH: 'AUDACYFLASH',
  ADMIN: 'ADMIN'
};

function getBackendUrl() {
  const host = window.location.hostname;
  if (host.endsWith('cumuluscreativeconcierge.com')) {
    return process.env.REACT_APP_CCC_NODE_URL;
  }

  if (host.endsWith('specbyte.com') || host.endsWith('audacyflash.com')) {
    return process.env.REACT_APP_SB_NODE_URL;
  }

  if (host.endsWith('cashbycreative.com')) {
    return process.env.REACT_APP_CXC_NODE_URL;
  }

  return process.env.REACT_APP_NODE_URL;
}

export function getSiteCode() {
  const host = window.location.hostname;
  if (host.endsWith('cumuluscreativeconcierge.com')) {
    return 'ccc';
  }
  if (host.endsWith('specbyte.com') || host.endsWith('audacyflash.com')) {
    return 'sb';
  }
  if (host.endsWith('cashbycreative.com')) {
    return 'cbc';
  }

  if (host.startsWith('localhost')) {
    const settings = JSON.parse(localStorage.getItem('settings'));
    if(!settings) return 'sb';
    if(settings.theme === THEMES.CCC) return 'ccc';
    if(settings.theme === THEMES.CASHBYCREATIVE) return 'cbc';
    if(settings.theme === THEMES.SPECBYTE) return 'sb';
  }

  return 'sb';
}

export const nodeURL = getBackendUrl()