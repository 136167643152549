import { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import NonAdminGuard from './components/NonAdminGuard';
import BrowseLayout from './components/BrowseLayout';
import BulkRequestLayout from './components/BulkRequestLayout';
import GuestGuard from './components/GuestGuard';
import NewUserGuard from './components/NewUserGuard';
import LoadingScreen from './components/LoadingScreen';
import FrontendLayout from './components/frontend/FrontendLayout';
import AccountLayout from './components/frontend/account/AccountLayout'
import AdminLayout from './components/admin/AdminLayout'
import AudacyAccountLayout from './components/frontend/audacyflash/AudacyAccountLayout';
import DemandView from './pages/frontend/demand';

const Loadable = (Component) => (props) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Browse pages

const Browse = Loadable(lazy(() => import('./pages/browse/Browse')));
const BrowseButtons = Loadable(lazy(() => import('./pages/browse/BrowseButtons')));
const BrowseCharts = Loadable(lazy(() => import('./pages/browse/BrowseCharts')));
const BrowseColors = Loadable(lazy(() => import('./pages/browse/BrowseColors')));
const BrowseDetailLists = Loadable(lazy(() => import('./pages/browse/BrowseDetailLists')));
const BrowseForms = Loadable(lazy(() => import('./pages/browse/BrowseForms')));
const BrowseGridLists = Loadable(lazy(() => import('./pages/browse/BrowseGridLists')));
const BrowseGroupedLists = Loadable(lazy(() => import('./pages/browse/BrowseGroupedLists')));
const BrowseInputs = Loadable(lazy(() => import('./pages/browse/BrowseInputs')));
const BrowseModals = Loadable(lazy(() => import('./pages/browse/BrowseModals')));
const BrowseQuickStats = Loadable(lazy(() => import('./pages/browse/BrowseQuickStats')));
const BrowseTables = Loadable(lazy(() => import('./pages/browse/BrowseTables')));
const BrowseTypography = Loadable(lazy(() => import('./pages/browse/BrowseTypography')));

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const LoginRedirect = Loadable(lazy(() => import('./pages/authentication/LoginRedirect')))
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));
const CompleteRegistration = Loadable(lazy(() => import('./pages/authentication/CompleteRegistration')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Other pages

const Checkout = Loadable(lazy(() => import('./pages/Checkout')));
const Pricing = Loadable(lazy(() => import('./pages/Pricing')));

// CCC Pages
const BulkRequest = Loadable(lazy(() => import('./pages/ccc/BulkRequest')));
const FullProduction = Loadable(lazy(() => import('./pages/ccc/FullProduction')));
const DryVoiceView = Loadable(lazy(() => import('./pages/ccc/DryVoiceOnly')));

// Frontend Pages 
const FrontendHome = Loadable(lazy(() => import('./pages/frontend/FrontendHome')));
const SearchResultsView = Loadable(lazy(() => import('./pages/frontend/search-results')));
const CustomRequestView = Loadable(lazy(() => import('./pages/frontend/custom')));
const ConciergeRequestView = Loadable(lazy(() => import('./pages/frontend/concierge')));
const ConciergeScriptAI = Loadable(lazy(() => import('./pages/frontend/concierge/scriptAI')));
const ConciergeRequest = Loadable(lazy(() => import('./pages/frontend/concierge/ConciergeRequest')));
const ConciergeRevise = Loadable(lazy(() => import('./pages/frontend/concierge/ConciergeRevise')));
const SpotRequest = Loadable(lazy(() => import('./pages/frontend/spotrequest/index')))
const AISpotRequest = Loadable(lazy(() => import('./pages/frontend/aispotrequest/index')))
const SpotRequestOptions = Loadable(lazy(() => import('./pages/frontend/spotrequestoptions/index')))
const BannerRequest = Loadable(lazy(() => import('./pages/frontend/banner/index')))
const PreRollsRequest = Loadable(lazy(() => import('./pages/frontend/pre-rolls/index')))
const AdvertisersView = Loadable(lazy(() => import('./pages/frontend/account/advertisers/index')));
const AdvertiserRequestsView = Loadable(lazy(() => import('./pages/frontend/account/advertisers/requests/index')));
const AdvertiserSpotRequestDetails = Loadable(lazy(() => import('./pages/frontend/account/advertisers/requests/SpotDetails')));

const HelpView = Loadable(lazy(() => import('./pages/frontend/help')));

const TermsPage = Loadable(lazy(() => import('./pages/frontend/TermsPage')))
const AboutPage = Loadable(lazy(() => import('./pages/frontend/AboutPage')))

const AccountView = Loadable(lazy(() => import('./pages/frontend/account')));

const AccountSpecs = Loadable(lazy(() => import('./pages/frontend/account/specs')))
const AccountSpecDetails = Loadable(lazy(() => import('./pages/frontend/account/specs/AccountSpecDetails')))
const EditSpecOptions = Loadable(lazy(() => import('./pages/frontend/account/specs/EditSpecOptions')))
const EditSpecSale = Loadable(lazy(() => import('./pages/frontend/account/specs/EditSpecSale')))

const AccountSpecials = Loadable(lazy(() => import('./pages/frontend/account/specials')))
const AccountSpecialDetails = Loadable(lazy(() => import('./pages/frontend/account/specials/AccountSpecialDetails')))
const EditSpecialOptions = Loadable(lazy(() => import('./pages/frontend/account/specials/EditSpecialOptions')))
const EditSpecialSale = Loadable(lazy(() => import('./pages/frontend/account/specials/EditSpecialSale')))

const AccountBanners = Loadable(lazy(() => import('./pages/frontend/account/banners')))
const AccountBannerDetails = Loadable(lazy(() => import('./pages/frontend/account/banners/AccountBannerDetails')))
const EditBannerOptions = Loadable(lazy(() => import('./pages/frontend/account/banners/EditBannerOptions')))
const EditBannerSale = Loadable(lazy(() => import('./pages/frontend/account/banners/EditBannerSale')))

const AccountPrerolls = Loadable(lazy(() => import('./pages/frontend/account/pre-rolls')))
const AccountPrerollDetails = Loadable(lazy(() => import('./pages/frontend/account/pre-rolls/AccountPrerollDetails')))
const AccountDemands = Loadable(lazy(() => import('./pages/frontend/account/demands')))
const AccountDemandDetails = Loadable(lazy(() => import('./pages/frontend/account/demands/AccountDemandDetails')))
const AccountMonitor = Loadable(lazy(() => import('./pages/frontend/account/monitor')))
const AccountManageUsers = Loadable(lazy(() => import('./pages/frontend/account/manage-users')))
const AccountConcierge = Loadable(lazy(() => import('./pages/frontend/account/concierge')))
const AccountConciergeDetails = Loadable(lazy(() => import('./pages/frontend/account/concierge/detail')))
const AccountDigitalCampaigns = Loadable(lazy(() => import('./pages/frontend/account/digital-campaigns')))
const AccountCustomRequests = Loadable(lazy(() => import('./pages/frontend/account/custom-requests')))
const AccountExternalSales = Loadable(lazy(() => import('./pages/frontend/account/external-sales')))
const AccountFavorites = Loadable(lazy(() => import('./pages/frontend/account/favorites')))
const AccountInfo = Loadable(lazy(() => import('./pages/frontend/account/account-info')))
const AccountRegister = Loadable(lazy(() => import('./pages/frontend/account/register')))
const AccountConfirmEmail = Loadable(lazy(() => import('./pages/frontend/account/confirm-email')))
const AccountReports = Loadable(lazy(() => import('./pages/frontend/account/reports')))
const ApproveReviseUpload = Loadable(lazy(() => import('./pages/frontend/account/approve-revise')))

///////////////////////////
// ADMIN DASHBOARD PAGES //
///////////////////////////

const AdminHome = Loadable(lazy(() => import('./pages/admin/AdminHome')))
const OverviewView = Loadable(lazy(() => import('./pages/admin/overview')));
const AdminHelpView = Loadable(lazy(() => import('./pages/admin/help')));

const SpotsManagementView = Loadable(lazy(() => import('./pages/admin/spots-management')));

const CategoriesView = Loadable(lazy(() => import('./pages/admin/spots-management/categories/index')));
const AddCategory = Loadable(lazy(() => import('./pages/admin/spots-management/categories/AddCategory')));
const ViewCategoryDetails = Loadable(lazy(() => import('./pages/admin/spots-management/categories/ViewCategoryDetails')));
const EditCategory = Loadable(lazy(() => import('./pages/admin/spots-management/categories/EditCategory')));

const ManageSpotsView = Loadable(lazy(() => import('./pages/admin/spots-management/manage-spots')));
const SearchSpotsView = Loadable(lazy(() => import('./pages/admin/spots-management/search-spots')));

const TagRequestsView = Loadable(lazy(() => import('./pages/admin/spots-management/tag-requests')));
const TagDetailsView = Loadable(lazy(() => import('./pages/admin/spots-management/tag-requests/detail')));

const BannerRequestsView = Loadable(lazy(() => import('./pages/admin/spots-management/banner-requests')));
const BannerDetailsView = Loadable(lazy(() => import('./pages/admin/spots-management/banner-requests/detail')));
const CustomDetailsView = Loadable(lazy(() => import('./pages/admin/spots-management/customs/details')));
const AdminCustomRequestsView = Loadable(lazy(() => import('./pages/admin/spots-management/customs/index')));

const PreRollRequestsView = Loadable(lazy(() => import('./pages/admin/spots-management/pre-roll-requests')));
const PreRollDetailsView = Loadable(lazy(() => import('./pages/admin/spots-management/pre-roll-requests/detail')));

const CampaignViews = Loadable(lazy(() => import('./pages/admin/spots-management/campaigns')));
const AddCampaignView = Loadable(lazy(() => import('./pages/admin/spots-management/campaigns/create')));
const EditCampaignView = Loadable(lazy(() => import('./pages/admin/spots-management/campaigns/edit')));

const VoicedProducedViews = Loadable(lazy(() => import('./pages/admin/spots-management/voiced-produced')));
const DigitalViews = Loadable(lazy(() => import('./pages/admin/spots-management/digital')));
const SpecRequestMatrixViews = Loadable(lazy(() => import('./pages/admin/spots-management/spec-request-matrix')));
const VendorsViews = Loadable(lazy(() => import('./pages/admin/spots-management/vendors')));

const ClientManagementView = Loadable(lazy(() => import('./pages/admin/client-management')));
const ClientManagementDemosViews = Loadable(lazy(() => import('./pages/admin/client-management/demos')));
const ClientManagementFederationsViews = Loadable(lazy(() => import('./pages/admin/client-management/federations')));
const ViewFederationDetails = Loadable(lazy(() => import('./pages/admin/client-management/federations/ViewFederationDetails')));
const AddFederation = Loadable(lazy(() => import('./pages/admin/client-management/federations/AddFederation')));
const ClientManagementManagersViews = Loadable(lazy(() => import('./pages/admin/client-management/managers')));
const ClientManagementMarketsViews = Loadable(lazy(() => import('./pages/admin/client-management/markets')));
const AddMarket = Loadable(lazy(() => import('./pages/admin/client-management/markets/AddMarket')));
const ViewMarketDetails = Loadable(lazy(() => import('./pages/admin/client-management/markets/ViewMarketDetails')));
const EditMarketDetails = Loadable(lazy(() => import('./pages/admin/client-management/markets/EditMarketDetails')));
const ClientManagementUsersViews = Loadable(lazy(() => import('./pages/admin/client-management/users')));
const ClientManagementEditUser = Loadable(lazy(() => import('./pages/admin/client-management/users/EditUser')));

const ProducerAdminViews = Loadable(lazy(() => import('./pages/admin/producers')));
const ProducerAdminProducerViews = Loadable(lazy(() => import('./pages/admin/producers/producers')));
const AddProducer = Loadable(lazy(() => import('./pages/admin/producers/producers/AddProducer')));
const ProducerAssignmentViews = Loadable(lazy(() => import('./pages/admin/producers/producer-assignments')));
const ProducerDialectsViews = Loadable(lazy(() => import('./pages/admin/producers/dialects')));
const ProducerLanguagesViews = Loadable(lazy(() => import('./pages/admin/producers/languages')));

const ConciergeSettingsView = Loadable(lazy(() => import('./pages/admin/concierge-settings')));
const ConciergeSettingsServicesViews = Loadable(lazy(() => import('./pages/admin/concierge-settings/services')));
const ConciergeSettingsRequestsViews = Loadable(lazy(() => import('./pages/admin/concierge-settings/requests')));
const ConciergeSettingsRequestsDetailsViews = Loadable(lazy(() => import('./pages/admin/concierge-settings/requests/detail')));
const ConciergeSettingsQuestionsViews = Loadable(lazy(() => import('./pages/admin/concierge-settings/questions')));

const SiteSettingsView = Loadable(lazy(() => import('./pages/admin/site-settings')));
const SiteSettingsDemoVideoViews = Loadable(lazy(() => import('./pages/admin/site-settings/demo-video')));
const SiteSettingsHomepageSliderViews = Loadable(lazy(() => import('./pages/admin/site-settings/homepage-slider')));
const SiteSettingsCustomAdminViews = Loadable(lazy(() => import('./pages/admin/site-settings/custom-admin')));
const SiteSettingsStationFormatsViews = Loadable(lazy(() => import('./pages/admin/site-settings/station-formats')));
const SiteSettingsProductionStatusViews = Loadable(lazy(() => import('./pages/admin/site-settings/production-status')));
const SiteSettingsCMSViews = Loadable(lazy(() => import('./pages/admin/site-settings/cms')));
const SiteSettingsRolesViews = Loadable(lazy(() => import('./pages/admin/site-settings/roles')));

const ReportsTrackingView = Loadable(lazy(() => import('./pages/admin/reports-tracking')));
const ReportsSimpleRevenueViews = Loadable(lazy(() => import('./pages/admin/reports-tracking/simple-revenue')));
const ReportsSpecbyteRevenueViews = Loadable(lazy(() => import('./pages/admin/reports-tracking/specbyte-revenue')));
const ReportsFederationsViews = Loadable(lazy(() => import('./pages/admin/reports-tracking/federation-reports')));
const ReportsTrackedItemsViews = Loadable(lazy(() => import('./pages/admin/reports-tracking/tracked-items')));
const ReportsCategoryReportViews = Loadable(lazy(() => import('./pages/admin/reports-tracking/category-report')));
const ReportsAssignmentQueuesViews = Loadable(lazy(() => import('./pages/admin/reports-tracking/assignment-queues')));

// AUDACY FLASH PAGES 
const AudacyHome = Loadable(lazy(() => import('./pages/audacyflash/')));
const SpecsViews = Loadable(lazy(() => import('./pages/audacyflash/specs')));
const CustomsView = Loadable(lazy(() => import('./pages/audacyflash/customs')));
const DigitalsView = Loadable(lazy(() => import('./pages/audacyflash/digitals')));
const AudacyAccountView = Loadable(lazy(() => import('./pages/audacyflash/account')));
const AudacyAccountSpecs = Loadable(lazy(() => import('./pages/audacyflash/account/specs')));
const AudacyAccountSpecDetails = Loadable(lazy(() => import('./pages/audacyflash/account/specs/AudacyAccountSpecDetails')));
const AudacyAccountCustoms = Loadable(lazy(() => import('./pages/audacyflash/account/customs')));
const AudacyAccountDigitals = Loadable(lazy(() => import('./pages/audacyflash/account/digitals')));
const AudacyAccountExternalSales = Loadable(lazy(() => import('./pages/audacyflash/account/external-sales')));
const AudacyAccountInfo = Loadable(lazy(() => import('./pages/audacyflash/account/account-info')));
const AudacyPendingApproval = Loadable(lazy(() => import('./pages/audacyflash/account/pending-approval')));
const AudacyPendingApprovalDetails = Loadable(lazy(() => import('./pages/audacyflash/account/pending-approval/AudacyPendingApprovalDetails')));
const AudacySpecApproved = Loadable(lazy(() => import('./pages/audacyflash/account/pending-approval/AudacySpecApproved')));
const AudacySpecRejected = Loadable(lazy(() => import('./pages/audacyflash/account/pending-approval/AudacySpecRejected')));

const routes = [
  {
    path: 'auth/azure/redirect',
    element: <GuestGuard><LoginRedirect /></GuestGuard>,
  },
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register />
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      },
      {
        path: 'complete-registration',
        element: (
          <NewUserGuard>
            <CompleteRegistration />
          </NewUserGuard>
        )
      },
      {
        path: 'confirmEmail',
        element: <AccountConfirmEmail />
      }
    ]
  },
  {
    path: '*',
    element: <FrontendLayout />,
    children: [
      {
        path: '/',
        element: <FrontendHome />,
      },
      {
        path: 'demand',
        element: <AuthGuard><DemandView /></AuthGuard>,
      },
      {
        path: 'search-results',
        element: <AuthGuard><SearchResultsView /></AuthGuard>
      },
      {
        path: 'customrequest',
        element: <AuthGuard><CustomRequestView /></AuthGuard>
      },
      {
        path: 'concierge',
        children: [
          {
            path: '/',
            element: <AuthGuard><ConciergeRequestView /></AuthGuard>
          },
          {
            path: ':conciergeServiceId',
            element: <AuthGuard><ConciergeRequest /></AuthGuard>
          },
          {
            path: '/scriptai',
            element: <AuthGuard><ConciergeScriptAI /></AuthGuard>
          },
        ]
      },
      {
        path: 'spotrequestoptions',
        children: [
          {
            path: '/:spotId',
            element: <AuthGuard><SpotRequestOptions /></AuthGuard>
          },
        ]
      },
      {
        path: 'spotrequest',
        children: [
          {
            path: '/:spotId',
            element: <AuthGuard><SpotRequest /></AuthGuard>
          },
        ]
      },
      {
        path: 'aispotrequest',
        children: [
          {
            path: '/:spotId',
            element: <AuthGuard><AISpotRequest /></AuthGuard>
          },
        ]
      },
      {
        path: 'banner',
        children: [
          {
            path: '/:spotId',
            element: <AuthGuard><BannerRequest /></AuthGuard>
          },
        ]
      },
      {
        path: 'preroll',
        children: [
          {
            path: '/:spotId',
            element: <AuthGuard><PreRollsRequest /></AuthGuard>
          },
        ]
      },
      {
        path: 'register',
        children: [
          {
            path: '/',
            element: <AccountRegister />
          }
        ]
      },
      {
        path: 'account',
        element: <AuthGuard><AccountLayout /></AuthGuard>,
        children: [
          {
            path: '/',
            element: <AuthGuard><AccountView /></AuthGuard>
          },
          {
            path: 'reports',
            element: <AuthGuard><AccountReports /></AuthGuard>
          },
          {
            path: 'approve-revise',
            children: [
              {
                path: '/:parentId/:uploadId',
                element: <AuthGuard><ApproveReviseUpload /></AuthGuard>
              }
            ]
          },
          {
            path: 'advertisers',
            children: [
              {
                path: '/',
                element: <AuthGuard><AdvertisersView /></AuthGuard>
              },
              {
                path: ':advertiserId/requests',
                element: <AuthGuard><AdvertiserRequestsView /></AuthGuard>
              },
              {
                path: ':advertiserId/requests/spot/:requestId',
                element: <AuthGuard><AdvertiserSpotRequestDetails /></AuthGuard>
              },
            ]
          },
          {
            path: 'myspecs',
            children: [
              {
                path: '/',
                element: <AccountSpecs />
              },
              {
                path: ':specId',
                element: <AccountSpecDetails />
              },
              {
                path: ':specId/options',
                element: <EditSpecOptions />
              },
              {
                path: ':specId/edit',
                element: <EditSpecSale />
              },
              {
                path: '/audio',
                children: [
                  {
                    path: '/',
                    element: <AccountSpecs />
                  },
                  {
                    path: ':specId',
                    element: <AccountSpecDetails />
                  },
                  {
                    path: ':specId/options',
                    element: <EditSpecOptions />
                  },
                  {
                    path: ':specId/edit',
                    element: <EditSpecSale />
                  },
                ]
              },
              {
                path: '/digital',
                children: [
                  {
                    path: '/',
                    element: <AccountBanners />
                  },
                  {
                    path: ':specId',
                    element: <AccountBannerDetails />
                  },
                  {
                    path: ':specId/options',
                    element: <EditBannerOptions />
                  },
                  {
                    path: ':specId/edit',
                    element: <EditBannerSale />
                  },
                ]
              },
            ]
          },
          {
            path: 'specials',
            children: [
              {
                path: '/',
                element: <AccountSpecials />
              },
              {
                path: ':specId',
                element: <AccountSpecialDetails />
              },
              {
                path: ':specId/options',
                element: <EditSpecialOptions />
              },
              {
                path: ':specId/edit',
                element: <EditSpecialSale />
              },
            ]
          },
          {
            path: 'customs',
            children: [
              {
                path: '/',
                element: <AccountSpecials />
              },
              {
                path: ':specId',
                element: <AccountSpecialDetails />
              },
              {
                path: ':specId/options',
                element: <EditSpecialOptions />
              },
              {
                path: ':specId/edit',
                element: <EditSpecialSale />
              },
            ]
          },
          {
            path: 'banners',
            children: [
              {
                path: '/',
                element: <AccountBanners />
              },
              {
                path: ':specId',
                element: <AccountBannerDetails />
              },
              {
                path: ':specId/options',
                element: <EditBannerOptions />
              },
              {
                path: ':specId/edit',
                element: <EditBannerSale />
              },
            ]
          },
          {
            path: 'prerolls',
            children: [
              {
                path: '/',
                element: <AccountPrerolls />
              },
              {
                path: ':specId',
                element: <AccountPrerollDetails />
              },
            ]
          },
          {
            path: 'demands',
            children: [
              {
                path: '/',
                element: <AccountDemands />
              },
              {
                path: ':specId',
                element: <AccountDemandDetails />
              },
            ]
          },
          {
            path: 'concierge',
            children: [
              {
                path: '/',
                element: <AccountConcierge />
              },
              {
                path: '/:conciergeId',
                element: <AccountConciergeDetails />
              },
              {
                path: '/type/:conciergeServiceId',
                element: <AccountConcierge />
              },
              {
                path: '/revise/:conciergeId',
                element: <ConciergeRevise />
              },
              {
                path: '/fullproductions',
                element: <AccountConcierge />
              },
              {
                path: '/dryvoice',
                element: <AccountConcierge />
              },
              {
                path: '/audio',
                element: <AccountConcierge />
              },
              {
                path: '/digital',
                element: <AccountConcierge />
              },
              {
                path: '/jingle',
                element: <AccountConcierge />
              },
            ]
          },
          {
            path: 'monitor',
            children: [
              {
                path: '/',
                element: <AccountMonitor />
              }
            ]
          },
          {
            path: 'manage-users',
            children: [
              {
                path: '/',
                element: <AccountManageUsers />
              }
            ]
          },
          {
            path: 'digitalcampaigns',
            element: <AccountDigitalCampaigns />
          },
          {
            path: 'customrequests',
            element: <AccountCustomRequests />
          },
          {
            path: 'externalsales',
            element: <AccountExternalSales />
          },
          {
            path: 'favorites',
            element: <AccountFavorites />
          },
          {
            path: 'accountinfo',
            element: <AccountInfo />
          },
          {
            path: 'confirmEmail',
            element: <AccountConfirmEmail />
          }
        ]
      },
      {
        path: 'admin',
        element: <NonAdminGuard>
          <AdminLayout />
        </NonAdminGuard>,
        children: [
          {
            path: '/',
            element: <AdminHome />
          },
          {
            path: 'spotsmanagement',
            children: [
              {
                path: '/',
                element: <SpotsManagementView />,
              },
              {
                path: 'categories',
                children: [
                  {
                    path: '/',
                    element: <CategoriesView />,
                  },
                  {
                    path: 'create',
                    element: <AddCategory />
                  },
                  {
                    path: ':categoryId',
                    element: <ViewCategoryDetails />
                  },
                  {
                    path: ':categoryId/edit',
                    element: <EditCategory />
                  },
                ]
              },
              {
                path: 'managespots',
                element: <ManageSpotsView />,
              },
              {
                path: 'searchspots',
                element: <SearchSpotsView />,
              },
              {
                path: 'tagrequests',
                children: [
                  {
                    path: '/',
                    element: <TagRequestsView />,
                  },
                  {
                    path: ':tagId',
                    element: <TagDetailsView />
                  }
                ]
              },
              {
                path: 'bannerrequests',
                children: [
                  {
                    path: '/',
                    element: <BannerRequestsView />,
                  },
                  {
                    path: ':bannerId',
                    element: <BannerDetailsView />
                  }
                ]
              },
              {
                path: 'customs',
                children: [
                  {
                    path: '/',
                    element: <AdminCustomRequestsView />,
                  },
                  {
                    path: ':customId',
                    element: <CustomDetailsView />
                  }
                ]
              },
              {
                path: 'prerollrequests',
                children: [
                  {
                    path: '/',
                    element: <PreRollRequestsView />,
                  },
                  {
                    path: ':preRollId',
                    element: <PreRollDetailsView />
                  }
                ]
              },
              {
                path: 'campaigns',
                children: [
                  {
                    path: '/',
                    element: <CampaignViews />,
                  },
                  {
                    path: 'create',
                    element: <AddCampaignView />
                  },
                  {
                    path: ':campaignId/edit',
                    element: <EditCampaignView />
                  },
                ]
              },
              {
                path: 'voiced-produced',
                element: <VoicedProducedViews />,
              },
              {
                path: 'digital',
                element: <DigitalViews />,
              },
              {
                path: 'spec-request-matrix',
                element: <SpecRequestMatrixViews />,
              },
              {
                path: 'vendors',
                element: <VendorsViews />,
              },
            ]
          },
          {
            path: 'clientmanagement',
            children: [
              {
                path: '/',
                element: <ClientManagementView />
              },
              {
                path: 'demos',
                element: <ClientManagementDemosViews />
              },
              {
                path: 'users',
                children: [
                  {
                    path: '/',
                    element: <ClientManagementUsersViews />
                  },
                  {
                    path: ':userId',
                    element: <ClientManagementEditUser />
                  }
                ]
              },
              {
                path: 'managers',
                element: <ClientManagementManagersViews />
              },
              {
                path: 'federations',
                children: [
                  {
                    path: '/',
                    element: <ClientManagementFederationsViews />
                  },
                  {
                    path: '/create',
                    element: <AddFederation />
                  },
                  {
                    path: '/:companyId',
                    element: <ViewFederationDetails />
                  },
                  {
                    path: '/:companyId/edit',
                    element: <AddFederation />
                  },
                ]
              },
              {
                path: 'markets',
                children: [
                  {
                    path: '/',
                    element: <ClientManagementMarketsViews />
                  },
                  {
                    path: '/create',
                    element: <AddMarket />
                  },
                  {
                    path: '/:marketId',
                    element: <ViewMarketDetails />
                  },
                  {
                    path: '/:marketId/edit',
                    element: <EditMarketDetails />
                  },
                ]
              },
            ]
          },
          {
            path: 'producer-admin',
            children: [
              {
                path: '/',
                element: <ProducerAdminViews />
              },
              {
                path: 'producers',
                children: [
                  {
                    path: '/',
                    element: <ProducerAdminProducerViews />
                  },
                  {
                    path: '/create',
                    element: <AddProducer />
                  }
                ]
              },
              {
                path: 'languages',
                element: <ProducerLanguagesViews />
              },
              {
                path: 'producer-assignments',
                element: <ProducerAssignmentViews />
              },
              {
                path: 'dialects',
                element: <ProducerDialectsViews />
              },
            ]
          },
          {
            path: 'concierge-settings',
            children: [
              {
                path: '/',
                element: <ConciergeSettingsView />
              },
              {
                path: 'services',
                element: <ConciergeSettingsServicesViews />
              },
              {
                path: 'requests',
                children: [
                  {
                    path: '/',
                    element: <ConciergeSettingsRequestsViews />
                  },
                  {
                    path: ':requestId',
                    element: <ConciergeSettingsRequestsDetailsViews />
                  },
                ]
              },
              {
                path: 'questions',
                element: <ConciergeSettingsQuestionsViews />
              },
            ]
          },
          {
            path: 'site-settings',
            children: [
              {
                path: '/',
                element: <SiteSettingsView />
              },
              {
                path: 'demovideo',
                element: <SiteSettingsDemoVideoViews />
              },
              {
                path: 'homeslider',
                element: <SiteSettingsHomepageSliderViews />
              },
              {
                path: 'customadmin',
                element: <SiteSettingsCustomAdminViews />
              },
              {
                path: 'stationformats',
                element: <SiteSettingsStationFormatsViews />
              },
              {
                path: 'productionstatus',
                element: <SiteSettingsProductionStatusViews />
              },
              {
                path: 'site-announcement',
                element: <SiteSettingsCMSViews />
              },
              {
                path: 'roles',
                element: <SiteSettingsRolesViews />
              }
            ]
          },
          {
            path: 'reports',
            children: [
              {
                path: '/',
                element: <ReportsTrackingView />
              },
              {
                path: 'simplerevenue',
                element: <ReportsSimpleRevenueViews />
              },
              {
                path: 'specbyterevenue',
                element: <ReportsSpecbyteRevenueViews />
              },
              {
                path: 'federationreports',
                element: <ReportsFederationsViews />
              },
              {
                path: 'trackeditems',
                element: <ReportsTrackedItemsViews />
              },
              {
                path: 'categoryreport',
                element: <ReportsCategoryReportViews />
              },
              {
                path: 'assignmentqueues',
                element: <ReportsAssignmentQueuesViews />
              },
            ]
          },
          {
            path: 'help',
            children: [
              {
                path: '/',
                element: <AdminHelpView />
              }
            ]
          },
        ]
      },
      {
        path: 'terms',
        children: [
          {
            path: '/',
            element: <TermsPage />
          }
        ]
      },
      {
        path: 'about',
        children: [
          {
            path: '/',
            element: <AboutPage />
          }
        ]
      },
      {
        path: 'audacyflash',
        children: [
          {
            path: '/',
            element: <AudacyHome />
          },
          {
            path: '/specs',
            element: <SpecsViews />
          },
          {
            path: '/customs',
            element: <CustomsView />
          },
          {
            path: '/digitals',
            element: <DigitalsView />
          },
          {
            path: 'account',
            element:
              <AuthGuard>
                <AudacyAccountLayout />
              </AuthGuard>,
            children: [
              {
                path: '/',
                element: <AudacyAccountView />
              },
              {
                path: '/pendingapproval',
                children: [
                  {
                    path: '/',
                    element: <AudacyPendingApproval />
                  },
                  {
                    path: ':specId',
                    children: [
                      {
                        path: '/',
                        element: <AudacyPendingApprovalDetails />
                      },
                      {
                        path: 'approved',
                        element: <AudacySpecApproved />
                      },
                      {
                        path: 'rejected',
                        element: <AudacySpecRejected />
                      },

                    ]
                  }
                ]
              },
              {
                path: 'specs',
                children: [
                  {
                    path: '/',
                    element: <AudacyAccountSpecs />
                  },
                  {
                    path: ':specId',
                    element: <AudacyAccountSpecDetails />
                  }
                ]
              },
              {
                path: '/customs',
                element: <AudacyAccountSpecs />
              },
              {
                path: '/digitals',
                element: <AudacyAccountDigitals />
              },
              {
                path: '/externalsales',
                element: <AudacyAccountExternalSales />
              },
              {
                path: '/accountinfo',
                element: <AudacyAccountInfo />
              },

            ]
          },
          {
            path: '/help',
            element: <HelpView />
          },
        ]
      },
      {
        path: 'help',
        element: <HelpView />
      },
      {
        path: 'bulk-request',
        element: (<AuthGuard>
          <BulkRequestLayout />
        </AuthGuard>),
        children: [
          {
            path: '/',
            element: <BulkRequest />
          },
          {
            path: '/full-production',
            element: <FullProduction />
          },
          {
            path: '/dry-voice',
            element: <DryVoiceView />,
          },
        ]
      },
      {
        path: 'browse',
        element: <BrowseLayout />,
        children: [
          {
            path: '/',
            element: <Browse />
          },
          {
            path: '/buttons',
            element: <BrowseButtons />
          },
          {
            path: '/inputs',
            element: <BrowseInputs />
          },
          {
            path: '/charts',
            element: <BrowseCharts />
          },
          {
            path: '/colors',
            element: <BrowseColors />
          },
          {
            path: '/data-display/detail-lists',
            element: <BrowseDetailLists />
          },
          {
            path: '/data-display/quick-stats',
            element: <BrowseQuickStats />
          },
          {
            path: '/data-display/tables',
            element: <BrowseTables />
          },
          {
            path: '/forms',
            element: <BrowseForms />
          },
          {
            path: '/modals',
            element: <BrowseModals />
          },
          {
            path: '/lists/grouped-lists',
            element: <BrowseGroupedLists />
          },
          {
            path: '/lists/grid-lists',
            element: <BrowseGridLists />
          },
          {
            path: '/typography',
            element: <BrowseTypography />
          }
        ]
      },
      {
        path: 'checkout',
        element: <Checkout />
      },
      {
        path: 'pricing',
        element: <Pricing />
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
