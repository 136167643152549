import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import { List, Divider } from '@material-ui/core';
import AdminNavItem from './AdminNavItem';
import useSettings from '../../hooks/useSettings';
import { Fragment } from 'react';
import { THEMES } from '../../constants';
const renderNavItems = ({ depth = 0, items, pathname, dividers }) => {
  return (
    <List disablePadding key={'renderNavItems-' + depth + '-' + (items?.length || 0) + '-' + items[0]?.title}>
      {items && items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) => reduceChildRoutes({
          acc,
          item,
          pathname,
          depth,
          dividers
        }), []
      )}
    </List>
  )
};

const reduceChildRoutes = ({ acc, pathname, item, depth, dividers }) => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path ? !!matchPath({
    path: item.path,
    end: true
  }, pathname) : false;
  if (item.title === 'PreRoll Requests') {
    const { settings } = useSettings();
    if (settings.theme === "CCC") return acc;
  }


  if (item.title === 'Custom Requests') {
    const { settings } = useSettings();
    if (settings.theme !== THEMES.CASHBYCREATIVE) return acc;
  }

  if (item.children) {
    const partialMatch = item.path ? !!matchPath({
      path: item.path,
      end: false
    }, pathname) : false;

    acc.push(<Fragment key={key}>
      <AdminNavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        passedkey={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname
        })}
      </AdminNavItem>
      {dividers && <Divider key={'divider-' + key} />}
    </Fragment>
    );
  } else {
    acc.push(<Fragment key={key}>
      <AdminNavItem
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        passedkey={key}
        path={item.path}
        title={item.title}
      />
      {dividers && <Divider key={'divider-' + key} />}
    </Fragment >
    );
  }

  return acc;
};

const AdminNavSection = (props) => {
  const { items, pathname, title, dividers, passedkey, ...other } = props;

  return (
    <List
      key={'list-' + passedkey}
    // subheader={(
    //   <ListSubheader
    //     disableGutters
    //     disableSticky
    //     sx={{
    //       color: 'text.primary',
    //       fontSize: '0.75rem',
    //       lineHeight: 2.5,
    //       fontWeight: 700,
    //       textTransform: 'uppercase'
    //     }}
    //   >
    //     {title}
    //   </ListSubheader>
    // )}
    // {...other}
    >
      {renderNavItems({
        items,
        pathname,
        dividers
      })}
    </List>
  );
};

AdminNavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string
};

export default AdminNavSection;
